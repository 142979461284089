import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap, of, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpHeaders = {
  headers: new HttpHeaders(environment.apiConfig.headers),
  reportProgress: true
}
const path = environment.apiConfig.path;
@Injectable({
  providedIn: 'root'
})

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  httpOptions: { headers: HttpHeaders; };
  urls: string;


  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
  }
  loginUser(username, password): Observable<any> {
    this.urls = path + "/auth/login";

    var datoaEnviar = {
      "email": username,
      "password": password
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  public logout() {
    localStorage.clear();
  }

  public isAuthenticated(): Observable<Boolean> {
    let res = localStorage.getItem('token') ? true : false;
    return of(res);
  }

  public getToken(): string | null {
    return localStorage.getItem('token');
  }

  articuloDetalle(id): Observable<any> {
    this.urls = path + "/prestamos/show-articulo/" + id;

    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }
}
