<mat-dialog-content>
  <!-- <div class="card overflow-auto">
    <div class="flex items-center">

      <h2 class="headline m-0 flex-auto">Clasificación</h2>

      <button class="text-secondary" mat-icon-button type="button" (click)="close()">
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>
  </div> -->

  <div class="card overflow-auto flex flex-col md:flex-row sm:mr-6">


    <div class="p-gutter flex-auto max-w-[400px]">

      <h3 class="body-2 m-0">Categorías</h3>

      <div class="mt-4 grid grid-cols-2">
        <button mat-stroked-button *ngFor="let item of categorias"
          style="background-color: #6366f1 !important; color: white;" (click)="selectCat(item)" class="m-2">
          <!-- <mat-icon [svgIcon]="feature.icon" class="icon-xl" color="primary"></mat-icon> -->
          <!-- <div class="body-2 mt-2" style="font-size: 10px;">{{ item.nombre }}</div> -->
          <span style="font-size: 10px;">
            <strong>
              {{ item.nombre }}
            </strong>
          </span>
        </button>
      </div>
    </div>

    <div class="p-gutter flex-auto max-w-[400px]" *ngIf="subcategorias.length">
      <h3 class="body-2 m-0">Sub-Categorias</h3>

      <!-- <div class="mt-6 grid grid-cols-2 gap-x-12 gap-y-4">
      <a (click)="close()" *ngFor="let page of subcategorias"
        class="text-dark body-1 no-underline hover:text-primary trans-ease-out">{{ page.label }}</a>
    </div> -->
      <div class="grid grid-cols-2 gap-2 font-mono text-white text-sm text-center font-bold leading-6 rounded-lg">
        <a *ngFor="let item of subcategorias" (click)="selectSub(item)" class="button" [ngStyle]="{
          'background-image': (item.foto) ? 'url(' + item.foto + ')' : ''         
      }">
          <!-- <mat-icon [svgIcon]="feature.icon" class="icon-xl" color="primary"></mat-icon> -->
          <!-- <img style="width: 50px;" [src]="item.foto" /> -->
          <div class="p-4 rounded-lg shadow-lg" style="font-size: 10px;" *ngIf="item.foto != null">{{ item.nombre }}
          </div>
          <div class="p-4 rounded-lg shadow-lg" style="font-size: 10px; color: black; background-color: #868a89;"
            *ngIf="item.foto == null">{{
            item.nombre }}</div>
        </a>
      </div>
    </div>

  </div>
</mat-dialog-content>

<style>
  .button {
    /* background: url(https://images.unsplash.com/photo-1497091071254-cc9b2ba7c48a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1353&q=80) no-repeat center; */
    background-size: cover;
    color: #fff;
  }
</style>