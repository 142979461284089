import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { LayoutService } from '../../@vex/services/layout.service';
import { filter, map, startWith } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '../../@vex/utils/check-router-childs-data';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ConfigService } from '../../@vex/config/config.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SidebarComponent } from '../../@vex/components/sidebar/sidebar.component';
import { LoadingService } from '../shared/services/loading.service';
import { SpinnerService } from '../shared/services/spinner.service';


@UntilDestroy()
@Component({
  selector: 'vex-custom-layout',
  templateUrl: './custom-layout.component.html',
  styleUrls: ['./custom-layout.component.scss']
})
export class CustomLayoutComponent implements OnInit, AfterViewInit {
  isLoading: boolean;
  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
  isDesktop$ = this.layoutService.isDesktop$;

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  );

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;

  constructor(private layoutService: LayoutService,
    private configService: ConfigService,
    private breakpointObserver: BreakpointObserver,
    public loadingService: LoadingService,
    private changeDetector: ChangeDetectorRef,

    public spinnerService: SpinnerService,

    private router: Router) {
    this.isLoading = true;
  }

  ngOnInit() {
    // this.layoutService.configpanelOpen$.pipe(
    //   untilDestroyed(this)
    // ).subscribe(open => open ? this.configpanel.open() : this.configpanel.close());
  }

  ngAfterViewInit(): void {
    // this.loadingService.httpProgress().subscribe((status: boolean) => {
    //   this.isLoading = status;
    //   this.changeDetector.detectChanges();
    // });
    // this.changeDetector.detectChanges();

    this.spinnerService.visibility.subscribe((data) => {
      this.isLoading = data;
      this.changeDetector.detectChanges();
    });
    this.changeDetector.detectChanges();
  }
}
