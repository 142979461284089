import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarNotificationsDropdownComponent } from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import Swal from 'sweetalert2';
import { SocketioService } from 'src/app/services/socketio.service';
import { DataResponseNotification } from 'src/app/shared/models/response/data-response-notification';
import { delayWhen, interval, Observable, of } from 'rxjs';

@Component({
  selector: 'vex-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarNotificationsComponent implements OnInit {

  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;
  dropdownOpen: boolean;
  notificationsCount$: Observable<number> = this.socketioService.notificationsCount$;

  constructor(private popover: PopoverService,
    private cd: ChangeDetectorRef,
    public socketioService: SocketioService) { }

  ngOnInit() {
    this.socketioService.lisent('traslado:'+localStorage.getItem('sucursal')).subscribe();
  }
  
  showPopover() {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarNotificationsDropdownComponent,
      origin: this.originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
}
