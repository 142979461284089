<div class="dropdown">
    <div class="dropdown-header flex items-center justify-between">
        <div>
            <div class="dropdown-heading">Concepto gastos
                <span class="m-4">
                    <button (click)="create('data')" mat-mini-fab color="danger" class="ml-4" matTooltip="Añadir">
                        <mat-icon svgIcon="mat:add"></mat-icon>
                    </button>
                </span>
            </div>
        </div>
    </div>

    <div class="dropdown-content">
        <mat-card *ngFor="let item of conceptos">
            <mat-card-content style="text-align: center;">
                <div class="flex flex-col sm:flex-row">
                    <div class="flex-auto mt-2">
                        <mat-label>{{item.descripcion}}</mat-label>
                    </div>

                    <div class="flex-auto">
                        <button mat-mini-fab color="danger" matTooltip="Editar" (click)="edit(item)">
                            <mat-icon svgIcon="mat:edit"></mat-icon>
                        </button>
                        <button mat-mini-fab color="danger" class="ml-2" matTooltip="Eliminar" (click)="delete(item)">
                            <mat-icon svgIcon="mat:delete" style="color: red;"></mat-icon>
                        </button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>