<form [formGroup]="form" *ngIf="modeStyle == ''">
    <div class="flex items-center" mat-dialog-title>

        <h2 *ngIf="form.get('nombre_completo').value" class="headline m-0 flex-auto">{{
            form.get('nombre_completo').value}}</h2>
        <h2 *ngIf="!form.get('nombre_completo').value" class="headline m-0 flex-auto">Nuevo
            empleado</h2>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <mat-dialog-content fxLayout="column">
        <div class="flex flex-col sm:flex-row">
            <mat-form-field appearance="outline" class="mt-6 flex-auto">
                <mat-label>Nombre completo</mat-label>
                <input cdkFocusInitial formControlName="nombre_completo" matInput>
            </mat-form-field>
        </div>

        <div class="grid grid-col-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
            <div class="mt-4" fxLayout="column" fxLayoutGap="8px">
                <mat-card class="example-card">
                    <img *ngIf="image" mat-card-image [src]="image" alt="image">
                    <mat-card-actions>
                        Foto:
                        <button type="button" mat-mini-fab color="primary" (click)="fileUpload.click()">
                            <mat-icon svgIcon="mat:attach_file"></mat-icon>
                        </button>
                        <input accept="image/png, image/jpeg" type="file" style="display: none;"
                            (change)="uploadFile($event)" #fileUpload>
                        <mat-hint style="color: red;" *ngIf="this.valid == false">Imágenes solamente</mat-hint>
                    </mat-card-actions>
                </mat-card>
            </div>

            <div class="mt-4" fxLayout="column" fxLayoutGap="8px">
                <mat-card class="example-card">
                    <img *ngIf="imageCi1" mat-card-image [src]="imageCi1" alt="image">
                    <mat-card-actions>
                        Foto carnet frontal:
                        <button type="button" mat-mini-fab color="primary" class="upload-btn"
                            (click)="inputFotoCiFront.click()">
                            <mat-icon svgIcon="mat:attach_file"></mat-icon>
                        </button>
                        <input hidden type="file" accept="image/*" #inputFotoCiFront (change)="uploadFile2($event)" />
                        <mat-hint style="color: red;" *ngIf="this.valid2 == false">Imagenes solamente</mat-hint>
                    </mat-card-actions>
                </mat-card>
            </div>
            <div class="mt-4" fxLayout="column" fxLayoutGap="8px">
                <mat-card class="example-card">
                    <img *ngIf="imageCi2" mat-card-image [src]="imageCi2" alt="image">
                    <mat-card-actions>
                        Foto carnet posterior:
                        <button type="button" mat-mini-fab color="primary" class="upload-btn"
                            (click)="inputFotoCiBack.click()">
                            <mat-icon svgIcon="mat:attach_file"></mat-icon>
                        </button>
                        <input hidden type="file" accept="image/*" #inputFotoCiBack (change)="uploadFile3($event)" />
                        <mat-hint style="color: red;" *ngIf="this.valid3 == false">Imagenes solamente</mat-hint>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>


        <div class="flex flex-col sm:flex-row">
            <mat-form-field appearance="outline" class="mt-6 flex-auto">
                <mat-label>Fecha nacimiento</mat-label>
                <input (click)="formDatepickerRef.open()" (focus)="formDatepickerRef.open()"
                    [matDatepicker]="formDatepickerRef" matInput readonly formControlName="fecha">
                <!-- <mat-datepicker-toggle [for]="formDatepickerRef" matSuffix></mat-datepicker-toggle> -->
                <mat-datepicker #formDatepickerRef></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline" class="sm:mt-6 sm:ml-6 flex-auto">
                <mat-label>Cargo</mat-label>
                <input cdkFocusInitial formControlName="cargo" matInput>
            </mat-form-field>
        </div>

        <ng-container *ngIf="isCreateMode()">
            <div class="flex flex-col sm:flex-row">
                <mat-form-field class="mt-6 flex-auto">
                    <mat-label>Buscar dirección</mat-label>
                    <input cdkFocusInitial matInput (keydown.enter)="$event.preventDefault()" #search
                        (keyup)="onKeyUpEvent($event)">
                </mat-form-field>
            </div>
            <google-map #myGoogleMap width="100%" height="300px" [zoom]="zoom" [options]="options" [center]="center"
                (mapClick)="eventHandler($event)">
                <map-marker #marker="mapMarker" *ngFor="let markerPosition of markers" [position]="markerPosition">
                </map-marker>
            </google-map>
        </ng-container>

        <div class="flex flex-col sm:flex-row">
            <mat-form-field appearance="outline" class="mt-6 flex-auto">
                <mat-label>Turno</mat-label>
                <input cdkFocusInitial formControlName="turno" matInput>
            </mat-form-field>

            <mat-form-field appearance="outline" class="sm:mt-6 sm:ml-6 flex-auto">
                <mat-label>Dirección</mat-label>
                <input cdkFocusInitial formControlName="direccion" matInput>
            </mat-form-field>
        </div>

        <div class="flex flex-col sm:flex-row">
            <mat-form-field appearance="outline" class="mt-6 flex-auto">
                <mat-label>Teléfono</mat-label>
                <input cdkFocusInitial maxlength="7" formControlName="telefono" (keypress)="keyPressNumbers($event)"
                    matInput>
            </mat-form-field>

            <mat-form-field appearance="outline" class="sm:mt-6 sm:ml-6 flex-auto">
                <mat-label>Celular</mat-label>
                <input cdkFocusInitial maxlength="8" formControlName="celular" (keypress)="keyPressNumbers($event)"
                    matInput>
            </mat-form-field>
        </div>

        <div class="flex flex-col sm:flex-row" *ngIf="isUpdateMode()">
            <mat-form-field appearance="outline" class="mt-6 flex-auto">
                <mat-label>Fecha Ingreso</mat-label>
                <input (click)="formDatepickerRef2.open()" (focus)="formDatepickerRef2.open()"
                    [matDatepicker]="formDatepickerRef2" matInput readonly formControlName="fecha_ingreso">
                <mat-datepicker #formDatepickerRef2></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline" class="sm:mt-6 sm:ml-6 flex-auto">
                <mat-label>Fecha Salida</mat-label>
                <input (click)="formDatepickerRef3.open()" (focus)="formDatepickerRef3.open()"
                    [matDatepicker]="formDatepickerRef3" matInput readonly formControlName="fecha_salida">
                <mat-datepicker #formDatepickerRef3></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="flex flex-col sm:flex-row" *ngIf="isCreateMode()">
            <mat-form-field appearance="outline" class="mt-6 flex-auto">
                <mat-label>Sucursal</mat-label>
                <input [formControl]="stateCtrl2" [matAutocomplete]="auto3" matInput>
                <mat-autocomplete #auto3="matAutocomplete" (optionSelected)='sel2($event)'>
                    <mat-option *ngFor="let destino of filteredStates$$ | async" [value]="destino.nombre"
                        [id]="destino">
                        <span class="body-1">{{ destino.nombre }}</span>
                    </mat-option>
                </mat-autocomplete>
                <button (click)="reset()" *ngIf="stateCtrl2.value" mat-icon-button matSuffix type="button">
                    <mat-icon svgIcon="mat:close"></mat-icon>
                </button>
                <button *ngIf="!stateCtrl2.value" class="text-secondary" mat-icon-button matSuffix type="button">
                    <mat-icon svgIcon="mat:arrow_drop_down"></mat-icon>
                </button>

                <mat-hint>Seleccionar la sucursal registrada</mat-hint>
            </mat-form-field>
        </div>

        <br>
        <h4 *ngIf="isCreateMode()">
            <strong>Accesos</strong>
        </h4>
        <div class="flex flex-col sm:flex-row" *ngIf="isCreateMode()">
            <mat-form-field appearance="outline" class="mt-6 flex-auto">
                <mat-label>Email</mat-label>
                <input cdkFocusInitial formControlName="email" matInput>
            </mat-form-field>

            <mat-form-field appearance="outline" class="sm:mt-6 sm:ml-6 flex-auto">
                <mat-label>Password</mat-label>
                <input type="password" cdkFocusInitial formControlName="password" matInput>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close type="button">CANCEL</button>
        <button *ngIf="isCreateMode()" class="btn-block" adiButton [loading]="loading[2]" (click)="add(2)"
            cdkFocusInitial>
            Guardar
            <ng-template adiButtonLoader> Guardando... </ng-template>
        </button>
        <button *ngIf="isUpdateMode()" class="btn-block" adiButton [loading]="loading[2]" (click)="edit(2)"
            cdkFocusInitial>
            Editar
            <ng-template adiButtonLoader> Editando... </ng-template>
        </button>
    </mat-dialog-actions>
</form>

<div *ngIf="modeStyle == 'sucursal'">
    <form [formGroup]="form">
        <div class="flex items-center" mat-dialog-title>

            <h2 class="headline m-0 flex-auto">Editar sucursal</h2>

            <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
                <mat-icon svgIcon="mat:close"></mat-icon>
            </button>
        </div>

        <mat-divider class="-mx-6 text-border"></mat-divider>

        <mat-dialog-content fxLayout="column">
            <div class="flex flex-col sm:flex-row">
                <mat-form-field appearance="outline" class="mt-6 flex-auto">
                    <mat-label>Sucursal</mat-label>
                    <input [formControl]="stateCtrl2" [matAutocomplete]="auto3" matInput>
                    <mat-autocomplete #auto3="matAutocomplete" (optionSelected)='sel2($event)'>
                        <mat-option *ngFor="let destino of filteredStates$$ | async" [value]="destino.nombre"
                            [id]="destino">
                            <span class="body-1">{{ destino.nombre }}</span>
                        </mat-option>
                    </mat-autocomplete>
                    <button (click)="reset()" *ngIf="stateCtrl2.value" mat-icon-button matSuffix type="button">
                        <mat-icon svgIcon="mat:close"></mat-icon>
                    </button>
                    <button *ngIf="!stateCtrl2.value" class="text-secondary" mat-icon-button matSuffix type="button">
                        <mat-icon svgIcon="mat:arrow_drop_down"></mat-icon>
                    </button>

                    <mat-hint>Seleccionar la sucursal registrada</mat-hint>
                </mat-form-field>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close type="button">CANCEL</button>
            <button class="btn-block" adiButton [loading]="loading[2]" (click)="editDireccion(2)" cdkFocusInitial>
                Editar
                <ng-template adiButtonLoader> Editando... </ng-template>
            </button>
        </mat-dialog-actions>
    </form>
</div>

<div *ngIf="modeStyle == 'formaciones'">
    <div class="flex items-center" mat-dialog-title>

        <h2 class="headline m-0 flex-auto">Formación académica</h2>

        <button (click)="bcreateFormacion(this.defaults.data)" class="ml-4" color="primary" fxFlex="none" mat-mini-fab
            matTooltip="Añadir" type="button">
            <mat-icon svgIcon="mat:add"></mat-icon>
        </button>
        <button class="ml-4" color="" fxFlex="none" mat-mini-fab mat-dialog-close mat-icon-button type="button"
            matTooltip="Cerrar">
            <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <mat-dialog-content fxLayout="column">
        <mat-list class="w-full list mat-elevation-z1">
            <mat-list-item *ngFor="let item of formaciones">
                <a color="warn" style="margin-left: 10px;" (click)="beditFormacion(item)"
                    class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary-light"
                    mat-icon-button matTooltip="Editar">
                    <mat-icon svgIcon="mat:edit" size="18px"></mat-icon>
                </a>
                <a style="margin-left: 10px; color: red;" (click)="bdeleteFormacion(item)"
                    class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary-light"
                    mat-icon-button matTooltip="Eliminar">
                    <mat-icon svgIcon="mat:delete" size="18px"></mat-icon>
                </a>
                <p matLine style="font-size: 15px;">
                    <b>Nombre: </b>{{item.nombre}}
                </p>
                <p matLine style="font-size: 15px;">
                    <b>Institución: </b>{{item.institucion}}
                </p>
                <p matLine style="font-size: 15px;">
                    <b>Nivel: </b>{{item.nivel}}
                </p>
                <p matLine style="font-size: 15px;">
                    <b>Egreso: </b>{{item.ano_egreso}}
                </p>
                <br>
                <mat-divider></mat-divider>
            </mat-list-item>
        </mat-list>
    </mat-dialog-content>
</div>

<div *ngIf="modeStyle == 'createForm'">
    <form [formGroup]="form">
        <div class="flex items-center" mat-dialog-title>

            <h2 *ngIf="form.get('nombre').value" class="headline m-0 flex-auto">{{
                form.get('nombre').value}}</h2>
            <h2 *ngIf="!form.get('nombre').value" class="headline m-0 flex-auto">Crear Formación</h2>

            <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
                <mat-icon svgIcon="mat:close"></mat-icon>
            </button>
        </div>

        <mat-divider class="-mx-6 text-border"></mat-divider>

        <mat-dialog-content fxLayout="column">
            <div class="flex flex-col sm:flex-row">
                <mat-form-field appearance="outline" class="mt-6 flex-auto">
                    <mat-label>Nombre</mat-label>
                    <input cdkFocusInitial formControlName="nombre" matInput>
                </mat-form-field>

                <mat-form-field appearance="outline" class="sm:mt-6 sm:ml-6 flex-auto">
                    <mat-label>Nivel</mat-label>
                    <input cdkFocusInitial formControlName="nivel" matInput>
                </mat-form-field>
            </div>

            <div class="flex flex-col sm:flex-row">
                <mat-form-field appearance="outline" class="mt-6 flex-auto">
                    <mat-label>Año Egreso</mat-label>
                    <input cdkFocusInitial formControlName="ano_egreso" (keypress)="keyPressNumbers($event)" matInput>
                </mat-form-field>

                <mat-form-field appearance="outline" class="sm:mt-6 sm:ml-6 flex-auto">
                    <mat-label>Institución</mat-label>
                    <input cdkFocusInitial formControlName="institucion" matInput>
                </mat-form-field>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close type="button">CANCEL</button>
            <button *ngIf="defaults.mode == 'createForm'" class="btn-block" adiButton [loading]="loading[2]"
                (click)="addFormacion(2)" cdkFocusInitial>
                Guardar
                <ng-template adiButtonLoader> Guardando... </ng-template>
            </button>
            <button *ngIf="defaults.mode == 'editForm'" class="btn-block" adiButton [loading]="loading[2]"
                (click)="editFormacion(2)" cdkFocusInitial>
                Editar
                <ng-template adiButtonLoader> Editando... </ng-template>
            </button>
        </mat-dialog-actions>
    </form>
</div>

<div *ngIf="modeStyle == 'delForm'">
    <div class="flex items-center" mat-dialog-title>

        <h2 class="headline m-0 flex-auto">Alerta</h2>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <p>Eliminar formación</p>
    </mat-dialog-content>


    <mat-dialog-actions align="end">
        <button style="margin-right: 10px;" mat-button mat-dialog-close>No</button>
        <button adiButton [loading]="loading[1]" variant="primary" (click)="deleteFormacion('Si', 1)">Si</button>
    </mat-dialog-actions>
</div>

<div *ngIf="modeStyle == 'accesso'">
    <form [formGroup]="form">
        <div class="flex items-center" mat-dialog-title>

            <h2 class="headline m-0 flex-auto">Editar accesos</h2>

            <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
                <mat-icon svgIcon="mat:close"></mat-icon>
            </button>
        </div>

        <mat-divider class="-mx-6 text-border"></mat-divider>

        <mat-dialog-content fxLayout="column">
            <div class="flex flex-col sm:flex-row">
                <mat-form-field appearance="outline" class="mt-6 flex-auto">
                    <mat-label>Email</mat-label>
                    <input cdkFocusInitial formControlName="email" matInput>
                </mat-form-field>

                <mat-form-field appearance="outline" class="sm:mt-6 sm:ml-6 flex-auto">
                    <mat-label>Password</mat-label>
                    <input type="password" cdkFocusInitial formControlName="password" matInput>
                </mat-form-field>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close type="button">CANCEL</button>
            <button class="btn-block" adiButton [loading]="loading[2]" (click)="editAccesos(2)" cdkFocusInitial>
                Editar
                <ng-template adiButtonLoader> Editando... </ng-template>
            </button>
        </mat-dialog-actions>
    </form>
</div>

<div *ngIf="modeStyle == 'asignar'">
    <form [formGroup]="form">
        <div class="flex items-center" mat-dialog-title>

            <h2 class="headline m-0 flex-auto">Asignar Rol</h2>

            <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
                <mat-icon svgIcon="mat:close"></mat-icon>
            </button>
        </div>

        <mat-divider class="-mx-6 text-border"></mat-divider>

        <mat-dialog-content fxLayout="column">
            <div class="flex flex-col sm:flex-row">
                <mat-form-field appearance="outline" class="mt-6 flex-auto">
                    <mat-label>Asignar rol</mat-label>
                    <mat-select formControlName="rol">
                        <mat-option *ngFor="let item of roles" [value]="item.id">
                            {{item.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close type="button">CANCEL</button>
            <button class="btn-block" adiButton [loading]="loading[2]" (click)="asignarUserRol(2)" cdkFocusInitial>
                Guardar
                <ng-template adiButtonLoader> Guardando... </ng-template>
            </button>
        </mat-dialog-actions>
    </form>
</div>

<div *ngIf="modeStyle == 'documentos'">
    <div class="flex items-center" mat-dialog-title>

        <h2 class="headline m-0 flex-auto">Certificaciones</h2>

        <button (click)="addBCertificacdp(this.defaults)" class="ml-4" color="primary" fxFlex="none" mat-mini-fab
            matTooltip="Añadir" type="button">
            <mat-icon svgIcon="mat:add"></mat-icon>
        </button>
        <button class="ml-4" color="" fxFlex="none" mat-mini-fab mat-dialog-close mat-icon-button type="button"
            matTooltip="Cerrar">
            <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <mat-dialog-content fxLayout="column">
        <mat-list class="w-full list mat-elevation-z1">
            <mat-list-item *ngFor="let item of certificaciones" class="my-2">
                <a style="margin-left: 10px;" *ngIf="item.pdf != 'null'" class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-primary
                bg-primary-light" mat-icon-button [href]="item.pdf_file" target="_blank" matTooltip="Ver PDF">
                    <mat-icon svgIcon="mat:remove_red_eye" size="18px"></mat-icon>
                </a>
                <a color="warn" style="margin-left: 10px;" (click)="addBEdtiCert(item)"
                    class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary-light"
                    mat-icon-button matTooltip="Editar">
                    <mat-icon svgIcon="mat:edit" size="18px"></mat-icon>
                </a>
                <a style="margin-left: 10px; color: red;" (click)="delBCert(item)"
                    class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary-light"
                    mat-icon-button matTooltip="Eliminar">
                    <mat-icon svgIcon="mat:delete" size="18px"></mat-icon>
                </a>
                <p matLine style="font-size: 15px;">
                    <b>Descripcion: </b>{{item.descripcion}}
                </p>
                <p matLine style="font-size: 15px;">
                    <b>Institución: </b>{{item.institucion}}
                </p>
                <p matLine style="font-size: 15px;">
                    <b>Puntaje: </b>{{item.puntaje}}
                </p>
                <p matLine style="font-size: 15px;">
                    <b>Tiempo de estudio: </b>{{item.tiempo_estudio}}
                </p>
                <p matLine style="margin: 10px;">
                    <img *ngIf="item.foto" [src]="item.foto" alt="all" style="width: 10%;">
                </p>
                <mat-divider></mat-divider>
            </mat-list-item>
        </mat-list>
    </mat-dialog-content>
</div>

<div *ngIf="modeStyle == 'createCerti'">
    <form [formGroup]="form">
        <div class="flex items-center" mat-dialog-title>

            <h2 class="headline m-0 flex-auto" *ngIf="defaults.mode == 'createCerti'">Registrar certificación</h2>
            <h2 class="headline m-0 flex-auto" *ngIf="defaults.mode == 'editCerti'">Editar certificación</h2>

            <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
                <mat-icon svgIcon="mat:close"></mat-icon>
            </button>
        </div>

        <mat-divider class="-mx-6 text-border"></mat-divider>

        <mat-dialog-content fxLayout="column">
            <div class="flex flex-col sm:flex-row">
                <mat-form-field appearance="outline" class="mt-6 flex-auto">
                    <mat-label>Institución</mat-label>
                    <input cdkFocusInitial formControlName="institucion" matInput>
                </mat-form-field>

                <mat-form-field appearance="outline" class="sm:mt-6 sm:ml-6 flex-auto">
                    <mat-label>Puntaje</mat-label>
                    <input formControlName="puntaje" (keypress)="keyPressNumbers($event)" matInput>
                </mat-form-field>
            </div>

            <div class="flex flex-col sm:flex-row">
                <mat-form-field appearance="outline" class="mt-2 flex-auto">
                    <mat-label>Tiempo estudio</mat-label>
                    <input cdkFocusInitial formControlName="tiempo_estudio" (keypress)="keyPressNumbers2($event)"
                        matInput>
                </mat-form-field>

                <mat-form-field appearance="outline" class="sm:mt-2 sm:ml-6 flex-auto">
                    <mat-label>Descripción</mat-label>
                    <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="3"
                        formControlName="descripcion"></textarea>
                </mat-form-field>
            </div>

            <div class="mt-4" fxLayout="column" fxLayoutGap="8px">
                <img style="width: 100px" *ngIf="image" mat-card-image [src]="image" alt="image">
                Foto:
                <button type="button" mat-mini-fab color="primary" (click)="fileUpload.click()">
                    <mat-icon svgIcon="mat:attach_file"></mat-icon>
                </button>
                <input accept="image/png, image/jpeg" type="file" style="display: none;" (change)="uploadFile($event)"
                    #fileUpload>
                <mat-hint style="color: red;" *ngIf="this.valid == false">Imágenes solamente</mat-hint>
            </div>

            <div class="mt-4 mb-4" fxLayout="column" fxLayoutGap="8px">
                PDF: {{pdf}}
                <button type="button" mat-mini-fab color="primary" (click)="fileUpload2.click()">
                    <mat-icon svgIcon="mat:attach_file"></mat-icon>
                </button>
                <input accept="application/pdf" type="file" style="display: none;" (change)="uploadPdf($event)"
                    #fileUpload2>
                <mat-hint style="color: red;" *ngIf="this.validPdf == false">PDF solamente</mat-hint>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close type="button">CANCEL</button>
            <button *ngIf="defaults.mode == 'createCerti'" class="btn-block" adiButton [loading]="loading[2]"
                (click)="addCertificacion(2)" cdkFocusInitial>
                Guardar
                <ng-template adiButtonLoader> Guardando... </ng-template>
            </button>
            <button *ngIf="defaults.mode == 'editCerti'" class="btn-block" adiButton [loading]="loading[2]"
                (click)="editCertificacion(2)" cdkFocusInitial>
                Editar
                <ng-template adiButtonLoader> Editando... </ng-template>
            </button>
        </mat-dialog-actions>
    </form>
</div>

<div *ngIf="modeStyle == 'delCerti'">
    <div class="flex items-center" mat-dialog-title>

        <h2 class="headline m-0 flex-auto">Alerta</h2>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <p>Eliminar Certificación</p>
    </mat-dialog-content>


    <mat-dialog-actions align="end">
        <button style="margin-right: 10px;" mat-button mat-dialog-close>No</button>
        <button adiButton [loading]="loading[1]" variant="primary" (click)="delCertificacion(1)">Si</button>
    </mat-dialog-actions>
</div>

<div *ngIf="modeStyle == 'direcciones'">
    <form [formGroup]="form">
        <div class="flex items-center" mat-dialog-title>

            <h2 class="headline m-0 flex-auto">Editar dirección</h2>

            <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
                <mat-icon svgIcon="mat:close"></mat-icon>
            </button>
        </div>

        <mat-divider class="-mx-6 text-border"></mat-divider>

        <mat-dialog-content fxLayout="column">

            <ng-container>
                <div class="flex flex-col sm:flex-row">
                    <mat-form-field class="mt-6 flex-auto">
                        <mat-label>Ubicación</mat-label>
                        <input cdkFocusInitial matInput (keydown.enter)="$event.preventDefault()" #search
                            (keyup)="onKeyUpEvent($event)">
                        <mat-icon class="mr-3" matPrefix svgIcon="mat:location_on"></mat-icon>
                    </mat-form-field>
                </div>
                <google-map #myGoogleMap width="100%" height="300px" [zoom]="zoom" [options]="options" [center]="center"
                    (mapClick)="eventHandler($event)">
                    <map-marker #marker="mapMarker" *ngFor="let markerPosition of markers" [position]="markerPosition">
                    </map-marker>
                </google-map>


                <div class="flex flex-col sm:flex-row">
                    <mat-form-field appearance="outline" class="mt-6 flex-auto">
                        <mat-label>Direccion</mat-label>
                        <input cdkFocusInitial formControlName="direccion" matInput>
                    </mat-form-field>
                </div>

            </ng-container>
            <br>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close type="button">CANCEL</button>
            <button class="btn-block" adiButton [loading]="loading[2]" (click)="editDireccion2(2)" cdkFocusInitial>
                Editar
                <ng-template adiButtonLoader> Editando... </ng-template>
            </button>
        </mat-dialog-actions>
    </form>
</div>

<div *ngIf="modeStyle == 'editPerfil'">
    <form [formGroup]="form">
        <div class="flex items-center" mat-dialog-title>

            <h2 class="headline m-0 flex-auto">Editar perfil</h2>

            <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
                <mat-icon svgIcon="mat:close"></mat-icon>
            </button>
        </div>

        <mat-divider class="-mx-6 text-border"></mat-divider>

        <mat-dialog-content fxLayout="column">
            <div class="flex flex-col sm:flex-row">
                <mat-form-field appearance="outline" class="mt-2 flex-auto">
                    <mat-label>Nueva contraseña</mat-label>
                    <input type="password" cdkFocusInitial formControlName="password" matInput>
                </mat-form-field>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close type="button">CANCEL</button>
            <button class="btn-block" adiButton [loading]="loading[2]" (click)="editPerfil(2)" cdkFocusInitial>
                Editar
                <ng-template adiButtonLoader> Editando... </ng-template>
            </button>
        </mat-dialog-actions>
    </form>
</div>