import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PopoverModule } from '../../../components/popover/popover.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { RelativeDateTimeModule } from '../../../pipes/relative-date-time/relative-date-time.module';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { ToolbarConceptoComponent } from './toolbar-notifications.component';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { ConceptoComponent } from 'src/app/pages/apps/concepto/detalle-create-update.component';

@NgModule({
  declarations: [ToolbarConceptoComponent, ConceptoComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    PopoverModule,
    MatTabsModule,
    MatMenuModule,
    RelativeDateTimeModule,
    RouterModule,
    MatRippleModule,
    AngularMaterialModule
  ],
  exports: [ToolbarConceptoComponent]
})
export class ToolbarConceptoModule {
}
