<!-- <div *ngIf="isLoading" style="width: 100vw;
  height: 100vh;
  background-color: #00000047;
  display: block;
  position: fixed;
  z-index: 999999;
  text-align: center;
  padding: 48vh 0 0 48vw;">
    <mat-spinner [diameter]="50">
    </mat-spinner>
  </div> -->

<div *ngIf="isLoading" style="
  width: 100vw;
  position: fixed;
  z-index: 999999;">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>



<ng-template #sidenavRef>
  <vex-sidenav [collapsed]="sidenavCollapsed$ | async"></vex-sidenav>
</ng-template>

<ng-template #toolbarRef>
  <vex-toolbar [hasShadow]="toolbarShadowEnabled$ | async" [mobileQuery]="!(isDesktop$ | async)"
    class="vex-toolbar"></vex-toolbar>
</ng-template>

<ng-template #footerRef>
  <vex-footer *ngIf="isFooterVisible$ | async" class="vex-footer"></vex-footer>
</ng-template>

<ng-template #quickpanelRef>
  <vex-quickpanel></vex-quickpanel>
</ng-template>

<vex-config-panel-toggle (openConfig)="configpanel.open()"></vex-config-panel-toggle>

<vex-layout [quickpanelRef]="quickpanelRef" [sidenavRef]="sidenavRef" [toolbarRef]="toolbarRef"></vex-layout>

<!-- CONFIGPANEL -->
<vex-sidebar #configpanel [invisibleBackdrop]="true" position="left">
  <vex-config-panel></vex-config-panel>
</vex-sidebar>
<!-- END CONFIGPANEL -->