import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, catchError, finalize, map, tap } from 'rxjs';
import { SpinnerService } from '../services/spinner.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  constructor(private spinnerService: SpinnerService) { }

  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

  //   this.spinnerService.show();

  //   return next.handle(req)
  //     .pipe(tap((event: HttpEvent<any>) => {
  //       if (event instanceof HttpResponse) {
  //         this.spinnerService.hide();
  //       }
  //     }, (error) => {
  //       this.spinnerService.hide();
  //     }));
  // }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.spinnerService.show();

    return next.handle(request).pipe(
      finalize(() => this.spinnerService.hide()),
    );
  }
}
