import { Component, OnInit } from '@angular/core';
import { Notification } from '../interfaces/notification.interface';
import { DateTime } from 'luxon';
import { trackById } from '../../../../utils/track-by';
import { Ser2Service } from 'src/app/services/ser2.service';
import { EncrDecrService } from 'src/app/services/encr-decr-service.service';
import { NotificacionService } from 'src/app/shared/services/notificacion.service';
import { DataResponse } from 'src/app/shared/models/response/data-response';

@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent implements OnInit {
  notifications: Array<Notification> | any;

  constructor(public service: Ser2Service,
    public notificacionService: NotificacionService,
    public descr: EncrDecrService) { }

  ngOnInit() {
    this.getNotificacionesBySucursal();
  }

  getNotificacionesBySucursal() {
    this.notificacionService.porSucursal( Number(localStorage.getItem('sucursal')) ).subscribe((data: DataResponse) => {
      this.notifications = data.data;
      console.log(this.notifications);
    });
  }
  getNotificacionesByUser() {
    this.service.mis_notificaciones(this.descr.get('123456$#@$^@1ERF', localStorage.getItem('keylog'))).subscribe((data) => {
      this.notifications = data.data;
      console.log(this.notifications);
    });
  }

}
