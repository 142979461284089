<div *ngIf="modeStyle == 'detalleArticulo'">
    <div class="flex items-center" mat-dialog-title>

        <h2 class="headline m-0 flex-auto">Detalle</h2>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>
    <mat-dialog-content fxLayout="column">
        <div class="flex flex-col sm:flex-row">
            <div class="mt-6 flex-auto">
                <div style="height: auto;">
                    <mat-carousel timings="250ms ease-in" [autoplay]="true" [interval]="5000" color="primary"
                        maxWidth="auto" [proportion]="100" [loop]="true" [hideArrows]="false" [hideIndicators]="false"
                        [useKeyboard]="true" [useMouseWheel]="false" orientation="ltr" [svgIconOverrides]="icons">
                        <mat-carousel-slide #matCarouselSlide style="width: 80%;"
                            *ngFor="let slide of fotos; let i = index" [image]="slide.foto" overlayColor="#00000040"
                            [hideOverlay]="true"></mat-carousel-slide>
                    </mat-carousel>
                </div>

                <mat-card class="w-full" *ngIf="defaults.data.cliente">
                    <mat-card-header>
                        <mat-card-title-group>
                            <mat-card-title>{{defaults.data.cliente.nombre_completo}}</mat-card-title>
                            <mat-card-subtitle>Cliente</mat-card-subtitle>
                            <img mat-card-sm-image *ngIf="defaults.data.cliente.foto" src="{{defaults.data.cliente.foto_thumbnail_sm}}" >
                            <img mat-card-sm-image *ngIf="!defaults.data.cliente.foto" src="assets/img/no-image.svg" >
                        </mat-card-title-group>
                    </mat-card-header>
                </mat-card>
                <div class="flex flex-col sm:flex-row">
                    <div class="mt-6 flex-auto">
                        <mat-list>
                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:attach_money" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>Bs. {{defaults.data.capital}}</div>
                                <div mat-line class="caption text-secondary"> Total de capital </div>
                            </mat-list-item>

                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:format_italic" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>Bs. {{defaults.data.interes}}</div>
                                <div mat-line class="caption text-secondary"> Interés </div>
                            </mat-list-item>
                        </mat-list>
                    </div>
                    <div class="mt-6 flex-auto">
                        <mat-list>
                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:lock" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>Bs. {{defaults.data.taza_conservacion}}</div>
                                <div mat-line class="caption text-secondary"> Conservación </div>
                            </mat-list-item>

                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:short_text" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>Bs. {{defaults.data.valor_comercial_articulo}}</div>
                                <div mat-line class="caption text-secondary"> V. comercial </div>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </div>
            </div>
            <div class="mt-6 flex-auto">
                <mat-card class="ml-2 place-items-center mat-elevation-z0">
                    <mat-card-header>
                        <mat-card-title>
                            Más datos
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-list>
                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:laptop_mac" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>{{defaults.data.nombre_articulo | titlecase}}</div>
                                <div mat-line class="caption text-secondary"> Producto </div>
                            </mat-list-item>

                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:local_offer" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>{{defaults.data.marca_articulo | titlecase}}</div>
                                <div mat-line class="caption text-secondary"> Marca </div>
                            </mat-list-item>

                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:reorder" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>{{defaults.data.categoria_nombre | titlecase }}</div>
                                <div mat-line class="caption text-secondary"> Categoría </div>
                            </mat-list-item>

                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:format_paint" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>{{defaults.data.mi_sub_categoria}}</div>
                                <div mat-line class="caption text-secondary"> Sub-Categoría </div>
                            </mat-list-item>

                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:settings" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>{{defaults.data.estado_uso_articulo}}</div>
                                <div mat-line class="caption text-secondary"> Estado </div>
                            </mat-list-item>

                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:description" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>{{defaults.data.detalle_articulo}}</div>
                                <div mat-line class="caption text-secondary"> Detalle </div>
                            </mat-list-item>
                            
                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:location_on" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>{{defaults.data.sucursal_nombre}}</div>
                                <div mat-line class="caption text-secondary"> Sucursal </div>
                            </mat-list-item>

                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:takeout_dining" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>{{defaults.data.almacen_nombre}}</div>
                                <div mat-line class="caption text-secondary"> Almacen </div>
                            </mat-list-item>

                            <mat-list-item *ngIf="defaults.data.prorrogado">
                                <mat-icon mat-list-icon svgIcon="mat:payments" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>{{defaults.data.prorrogado? 'Si': 'No'}}</div>
                                <div mat-line class="caption text-secondary"> Prorrogado </div>
                            </mat-list-item>
                            
                            <mat-list-item *ngIf="defaults.data.detalle_espec != ''">
                                <mat-icon mat-list-icon svgIcon="mat:remove_red_eye" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line [innerHTML]="defaults.data.detalle_espec"></div>
                                <div mat-line class="caption text-secondary"> Incluye </div>
                            </mat-list-item>
                        </mat-list>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </mat-dialog-content>
</div>

<div *ngIf="modeStyle == 'multiple_registro'">
    <div [formGroup]="form">
        <div class="flex items-center" mat-dialog-title>

            <h2 class="headline m-0 flex-auto">Artículos pendientes</h2>

            <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
                <mat-icon svgIcon="mat:close"></mat-icon>
            </button>
        </div>

        <mat-divider class="-mx-6 text-border"></mat-divider>
        <mat-dialog-content fxLayout="column">
            <div class="flex flex-col sm:flex-row">
                <mat-form-field appearance="outline" class="mt-4 flex-auto">
                    <mat-label>Sucursal</mat-label>
                    <mat-select cdkFocusInitial formControlName="sucursal_id">
                        <mat-option *ngFor="let item of sucursales" (click)="selectSucursal(item)"
                            [value]="item.id">{{item.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="sm:mt-4 sm:ml-4 flex-auto">
                    <mat-label>Almacén de la sucursal</mat-label>
                    <mat-select formControlName="almacen_destiono">
                        <mat-option *ngFor="let item of almacenes" [value]="item.id">{{item.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="flex flex-col sm:flex-row">
                <mat-form-field appearance="outline" class="mt-4 flex-auto">
                    <mat-label>Precauciones</mat-label>
                    <input formControlName="precauciones" matInput>
                </mat-form-field>

                <mat-form-field appearance="outline" class="sm:mt-4 sm:ml-4 flex-auto">
                    <mat-label>Observaciones</mat-label>
                    <input formControlName="observaciones" matInput>
                </mat-form-field>
            </div>

            <div class="flex flex-col sm:flex-row">
                <mat-form-field appearance="outline" class="mt-4 flex-auto">
                    <mat-label>Fecha</mat-label>
                    <input (click)="formDatepickerRef2.open()" (focus)="formDatepickerRef2.open()"
                        [matDatepicker]="formDatepickerRef2" matInput readonly formControlName="fecha">
                    <mat-datepicker #formDatepickerRef2></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline" class="sm:mt-4 sm:ml-4 flex-auto">
                    <mat-label>Hora</mat-label>
                    <input matInput [format]="24" formControlName="hora" [ngxMatTimepicker]="pickerD" readonly />
                </mat-form-field>
                <div class="ngx-mtp-box-bordered">
                    <ngx-mat-timepicker color="accent" appendToInput="true" #pickerD></ngx-mat-timepicker>
                </div>
            </div>

            <section class="example-section m-6">
                <span class="example-list-section">
                    <ul>
                        <li *ngFor="let item of pendientes">
                            <mat-checkbox [disabled]="!form.valid" (change)="changeArticulos($event, item)">
                                Nombre Artículo: {{item.nombre_articulo}}
                                Ver Artículo: <button mat-mini-fab matTooltip="Ver artículo"
                                    (click)="detailArticulo(item)" type="button">
                                    <mat-icon svgIcon="mat:visibility"></mat-icon>
                                </button>
                            </mat-checkbox>
                        </li>
                    </ul>
                </span>
            </section>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close type="button">CANCEL</button>
            <button class="btn-block" adiButton [loading]="loading[2]" (click)="add(2)">
                Guardar
                <ng-template adiButtonLoader> Guardando... </ng-template>
            </button>
        </mat-dialog-actions>
    </div>
</div>

<div *ngIf="modeStyle == 'detalleArticulo2'">
    <div class="flex items-center" mat-dialog-title>

        <h2 class="headline m-0 flex-auto">Detalle</h2>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>
    <mat-dialog-content fxLayout="column">
        <div class="flex flex-col sm:flex-row">
            <div class="mt-6 flex-auto">
                <div style="height: auto;">
                    <mat-carousel timings="250ms ease-in" [autoplay]="true" [interval]="5000" color="primary"
                        maxWidth="auto" [proportion]="100" [loop]="true" [hideArrows]="false" [hideIndicators]="false"
                        [useKeyboard]="true" [useMouseWheel]="false" orientation="ltr" [svgIconOverrides]="icons">
                        <mat-carousel-slide #matCarouselSlide style="width: 80%;"
                            *ngFor="let slide of fotos; let i = index" [image]="slide.foto" overlayColor="#00000040"
                            [hideOverlay]="true"></mat-carousel-slide>
                    </mat-carousel>
                </div>

                <div class="flex flex-col sm:flex-row">
                    <div class="mt-6 flex-auto">
                        <mat-list>
                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:attach_money" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>Bs. {{defaults.data.capital}}</div>
                                <div mat-line class="caption text-secondary"> Total de capital </div>
                            </mat-list-item>

                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:format_italic" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>Bs. {{defaults.data.interes}}</div>
                                <div mat-line class="caption text-secondary"> Interés </div>
                            </mat-list-item>
                        </mat-list>
                    </div>
                    <div class="mt-6 flex-auto">
                        <mat-list>
                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:lock" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>Bs. {{defaults.data.taza_conservacion}}</div>
                                <div mat-line class="caption text-secondary"> Conservación </div>
                            </mat-list-item>

                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:short_text" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>Bs. {{defaults.data.valor_comercial}}</div>
                                <div mat-line class="caption text-secondary"> V. comercial </div>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </div>
            </div>
            <div class="mt-6 flex-auto">
                <mat-card class="ml-2 place-items-center mat-elevation-z0">
                    <mat-card-header>
                        <mat-card-title>
                            Más datos
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-list>
                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:laptop_mac" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>{{defaults.data.articulo.nombre | titlecase}}</div>
                                <div mat-line class="caption text-secondary"> Producto </div>
                            </mat-list-item>

                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:local_offer" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>{{defaults.data.articulo.marca | titlecase}}</div>
                                <div mat-line class="caption text-secondary"> Marca </div>
                            </mat-list-item>

                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:reorder" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>{{defaults.data.articulo.categoria_nombre | titlecase }}</div>
                                <div mat-line class="caption text-secondary"> Categoría </div>
                            </mat-list-item>

                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:format_paint" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>{{defaults.data.articulo.sub_categoria_nombre}}</div>
                                <div mat-line class="caption text-secondary"> Sub-Categoría </div>
                            </mat-list-item>

                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:settings" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>{{defaults.data.articulo.estado_uso}}</div>
                                <div mat-line class="caption text-secondary"> Estado </div>
                            </mat-list-item>

                            <mat-list-item>
                                <mat-icon mat-list-icon svgIcon="mat:description" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line>{{defaults.data.articulo.detalle}}</div>
                                <div mat-line class="caption text-secondary"> Detalle </div>
                            </mat-list-item>

                            <mat-list-item *ngIf="defaults.data.detalle_espec != ''">
                                <mat-icon mat-list-icon svgIcon="mat:remove_red_eye" class="bg-stone-800"
                                    style="color: white;"></mat-icon>
                                <div mat-line [innerHTML]="defaults.data.articulo.detalle_espec"></div>
                                <div mat-line class="caption text-secondary"> Incluye </div>
                            </mat-list-item>
                        </mat-list>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </mat-dialog-content>
</div>