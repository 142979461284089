import { Component, OnInit } from '@angular/core';
import { AlmacenCreateUpdateComponent } from '../almacenes/almacen-create-update/almacen-create-update.component';
import { MatDialog } from '@angular/material/dialog';
import { Ser3Service } from 'src/app/services/ser3.service';

@Component({
  selector: 'vex-detalle-create-update',
  templateUrl: './detalle-create-update.component.html',
  styleUrls: ['./detalle-create-update.component.scss']
})
export class ConceptoComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private service: Ser3Service
  ) { }

  ngOnInit(): void {
    this.getCuentas();
  }

  create(data) {
    this.dialog.open(AlmacenCreateUpdateComponent, {
      width: '500px',
      height: 'auto',
      data: { data: data, mode: 'createConcepto' }
    }).afterClosed().subscribe((data: any) => {
      if (data) {
        this.getCuentas();
      }
    });
  }

  edit(data) {
    this.dialog.open(AlmacenCreateUpdateComponent, {
      width: '500px',
      height: 'auto',
      data: { data: data, mode: 'editConcepto' }
    }).afterClosed().subscribe((data: any) => {
      if (data) {
        this.getCuentas();
      }
    });
  }

  delete(data) {
    this.dialog.open(AlmacenCreateUpdateComponent, {
      width: '400px',
      height: 'auto',
      data: { data: data, mode: 'deleteConcepto' }
    }).afterClosed().subscribe((data: any) => {
      if (data == 'Si') {
        this.getCuentas();
      }
    });
  }

  conceptos: any[];
  getCuentas() {
    this.service.getConceptogastos().subscribe((data) => {
      if (data.data instanceof Array) {
        this.conceptos = data.data;
      }
    })
  }
}
