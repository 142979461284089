import { Component, OnInit } from '@angular/core';
import { AlmacenCreateUpdateComponent } from '../almacenes/almacen-create-update/almacen-create-update.component';
import { MatDialog } from '@angular/material/dialog';
import { Ser2Service } from 'src/app/services/ser2.service';

@Component({
  selector: 'vex-detalle-create-update',
  templateUrl: './detalle-create-update.component.html',
  styleUrls: ['./detalle-create-update.component.scss']
})
export class DetalleCreateUpdateComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private service: Ser2Service
  ) { }

  ngOnInit(): void {
    this.getCuentas();
  }

  create(data) {
    this.dialog.open(AlmacenCreateUpdateComponent, {
      width: '500px',
      height: 'auto',
      data: { data: data, mode: 'createDetalle' }
    }).afterClosed().subscribe((data: any) => {
      if (data) {
        this.getCuentas();
      }
    });
  }

  edit(data) {
    this.dialog.open(AlmacenCreateUpdateComponent, {
      width: '500px',
      height: 'auto',
      data: { data: data, mode: 'editDetalle' }
    }).afterClosed().subscribe((data: any) => {
      if (data) {
        this.getCuentas();
      }
    });
  }

  delete(data) {
    this.dialog.open(AlmacenCreateUpdateComponent, {
      width: '400px',
      height: 'auto',
      data: { data: data, mode: 'deleteDetalle' }
    }).afterClosed().subscribe((data: any) => {
      if (data == 'Si') {
        this.getCuentas();
      }
    });
  }

  cuentas: any[];
  getCuentas() {
    this.service.getCuentas().subscribe((data) => {
      if (data.data instanceof Array) {
        this.cuentas = data.data;
      }
    })
  }
}
