import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { of, throwError, Observable, tap } from 'rxjs';
import { DataResponse } from 'src/app/shared/models/response/data-response';
import { MessageResponse } from 'src/app/shared/models/response/message-response';
import { Pagination } from 'src/app/shared/models/pagination';
import { environment } from 'src/environments/environment';
import { Movimiento } from '../models/movimiento';

const httpHeaders = {
  headers: new HttpHeaders(environment.apiConfig.headers),
  reportProgress: true
}
const path = environment.apiConfig.path;

@Injectable({
  providedIn: 'root'
})
export class NotificacionService {

  constructor(private http: HttpClient) {
  }

  public porSucursal(idSucursal: number): Observable<DataResponse> {
    return this.http.get<DataResponse>(`${path}/notificaciones/${idSucursal}`, httpHeaders);
  }
}
